import store from '@/store'
import i18n from '@/libs/i18n'
// import incomingInvoice from '../../../store/incomingInvoice2/incomingInvoice'

export const useSupplierInvoices = () => {

  const submitValidatedIncomingInvoice = (incomingInvoice) => {
    return new Promise((resolve, reject) => {
      if ('id' in incomingInvoice && incomingInvoice.id != null) {
        updateIncomingInvoice(incomingInvoice)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewIncomingInvoice(incomingInvoice)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewIncomingInvoice = (incomingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('incomingInvoice2/addIncomingInvoice', incomingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateIncomingInvoice = (incomingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('incomingInvoice/updateIncomingInvoice', incomingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const recognizeIncomingInvoice = (incomingInvoiceDocument) => {
    return new Promise((resolve, reject) => {
      store.dispatch('document/recognizeDocument', [incomingInvoiceDocument, 'incomingInvoice'])
        .then(response => {
          if (response.data.paymentMethod) {
            response.data.paymentMethod.name = i18n.t(response.data.paymentMethod.name)
          }
          if (response.data.paymentTerm) {
            response.data.paymentTerm.term = i18n.t(response.data.paymentTerm.term)
          }

          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeIncomingInvoice = (incomingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('incomingInvoice/removeIncomingInvoice', incomingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadIncomingInvoice = (incomingInvoice) => {
    if (incomingInvoice.receipt != null) {
      let link = document.createElement('a')
      link.href = incomingInvoice.receipt.url
      link.target = '_blank'
      link.download = incomingInvoice.receipt.name + '.' + incomingInvoice.receipt.extension
      link.click()
    }
  }

  return {
    submitValidatedIncomingInvoice,
    removeIncomingInvoice,
    recognizeIncomingInvoice,
    downloadIncomingInvoice,
  }
}