<template>
  <b-col cols="12">
    <b-row>
      <b-col md="4">
        <!-- Document -->
        <field-file
            :name="'socialSecurityContribution'"
            :isRequired="true"
            :singleFile.sync="socialSecurityContribution.document"
            :documentType="'socialSecurityContribution'"
            :listIndex="index"
        />
      </b-col>
      <b-col md="8">
        <b-row>

          <!-- Supplier -->
          <b-col md="6">
            <field-contact
                :name="$t('supplier')"
                :customerCompany.sync="socialSecurityContribution.supplier"
                :isRequired="true"
                :isCompanyOnly="true"
            />
          </b-col>

          <!-- Month -->
          <b-col v-if="['md','lg', 'xl'].includes(currentBreakPoint)"
                 :md="isEdition?6:5">
            <field-date
                :name="'month'"
                :rules="'required'"
                :model.sync="socialSecurityContribution.month"
                :isRequired="true"
                :listIndex="index"
                :onlyMonth="true"
            />
          </b-col>

          <!-- Month -->
          <b-col v-if="!['md','lg', 'xl'].includes(currentBreakPoint)"
                 :cols="isEdition?12:10">
            <field-date
                :name="'month'"
                :rules="'required'"
                :model.sync="socialSecurityContribution.month"
                :isRequired="true"
                :listIndex="index"
                :onlyMonth="true"
            />
          </b-col>

          <b-col v-if="!isEdition && !['md','lg', 'xl'].includes(currentBreakPoint)"
                 cols="2"
                 style="padding-top: 30px">
            <button-remove
                size="sm"
                :disabled="!allowRemove"
                :withText="false"
                :withBorder="false"
                :withIcon="true"
                @click="allowRemove?$emit('removeSocialSecurityContribution'):false"
            />
          </b-col>

          <b-col v-if="!isEdition && ['md','lg', 'xl'].includes(currentBreakPoint)"
                 md="1"
                 style="padding-top: 30px">
            <button-remove
                size="sm"
                :disabled="!allowRemove"
                :withText="false"
                :withBorder="false"
                :withIcon="true"
                @click="allowRemove?$emit('removeSocialSecurityContribution'):false"
            />
          </b-col>

          <!-- Pre tax amount -->
          <b-col md="4">
            <field-input
                :name="'preTaxAmount'"
                :rules="'required|decimal|min_value:0'"
                :model.sync="socialSecurityContribution.preTaxAmount"
                :listIndex="index"
            />
          </b-col>

          <!-- Payment method -->
          <b-col md="4">
            <field-select
                :model.sync="socialSecurityContribution.paymentMethod"
                :name="'paymentMethod'"
                :options="paymentMethods"
                :label="'name'"
                :listIndex="index"
                :isRequired="true"
                :clearable="false"
            />
          </b-col>

          <!-- Due date -->
          <b-col md="4">
            <field-date
                :name="'dueDate'"
                :rules="'required'"
                :model.sync="socialSecurityContribution.dueDate"
                :isRequired="true"
                :listIndex="index"
            />
          </b-col>

        </b-row>
      </b-col>

    </b-row>
  </b-col>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import store from '@/store'
import useAPI from '@/utils/useAPI'
import FieldInput from '@/components/input/Input'
import FieldFile from '@/components/input/File'
import FieldSelect from '@/components/input/Select'
import FieldDate from '@/components/input/Date'
import FieldContact from '@/components/input/Contact'
import ButtonRemove from '@/components/button/Remove'

export default {
  components: {
    FieldInput,
    FieldFile,
    FieldSelect,
    FieldDate,
    FieldContact,
    ButtonRemove
  },
  props: {
    socialSecurityContribution: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    allowRemove: {
      type: Boolean
    },
    isEdition: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { paymentMethods } = useAPI()

    const currentBreakPoint = computed(() => {
      return store.getters['app/currentBreakPoint']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      paymentMethods,
      currentBreakPoint,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>