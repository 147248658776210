<template>
  <div class="mt-3">
    <b-overlay :show="isLoading">

      <h1>{{ capitalize($t('banks')) }}</h1>

      <div class="accountingCashFlow">

        <div
            v-for="bankItem in banks"
            class="accountingCashFlow p-50 mb-1"
        >
          <div class="d-flex justify-content-between">
            <div class="w-100">
              <div>{{bankItem.bankAccount}}</div>
              <small class="text-muted">
                <i>{{capitalize($tc('unreconciledTransaction', bankItem.unreconciledTransactions, {number: bankItem.unreconciledTransactions}))}}</i>
              </small>
            </div>
            <div
                class="my-auto text-nowrap"
                :class="'xxx'>=0?'text-success':'text-warning'"
            >
              {{currency(bankItem.balance)}}
            </div>
          </div>
        </div>

      </div>

    </b-overlay>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../../utils/filter'
import { isEmptyObject } from '../../../../utils/utils'

export default {
  components: {},
  props: {
    banks: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isLoading = computed(() => {
      if (props.banks.length == 0) {
        return true
      } else {
        return false
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data

      // Computed
      isLoading,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>