<template>
  <b-col cols="12">
    <b-row>
      <b-col md="4">
        <field-file
            :name="'paySlip'"
            :isRequired="true"
            :singleFile.sync="salary.paySlip"
            :documentType="'salary'"
            :listIndex="index"
        />
      </b-col>
      <b-col md="8">
        <b-row>

          <!--    Employee-->
          <b-col md="6">
            <field-select
                :model.sync="salary.employee"
                :name="'employee'"
                :options="employees"
                :label="'_display'"
                :isRequired="true"
                :listIndex="index"
            />
          </b-col>

          <!--    Month-->
          <b-col
              v-if="['md','lg', 'xl'].includes(currentBreakPoint)"
              :md="isEdition?6:5">
            <field-date
                :name="'month'"
                :rules="'required'"
                :model.sync="salary.month"
                :isRequired="true"
                :listIndex="index"
                :onlyMonth="true"
            />
          </b-col>


          <!--    Month-->
          <b-col
              v-if="!['md','lg', 'xl'].includes(currentBreakPoint)"
              :cols="isEdition?12:10">
            <field-date
                :name="'month'"
                :rules="'required'"
                :model.sync="salary.month"
                :isRequired="true"
                :listIndex="index"
                :onlyMonth="true"
            />
          </b-col>

          <b-col v-if="!isEdition && !['md','lg', 'xl'].includes(currentBreakPoint)"
                 cols="2">
            <div style="padding-top: 30px"
                 @click="allowRemove?$emit('removeSalary'):false"
                 class="text-danger"
                 :class="{'cursor-pointer': allowRemove}"
            >
              <icon
                  :disabled="!allowRemove"
                  icon="times"/>
            </div>
          </b-col>

          <b-col v-if="!isEdition && ['md','lg', 'xl'].includes(currentBreakPoint)"
                 md="1">
            <div style="padding-top: 30px"
                 @click="allowRemove?$emit('removeSalary'):false"
                 class="text-danger"
                 :class="{'cursor-pointer': allowRemove}"
            >
              <icon
                  :disabled="!allowRemove"
                  icon="times"/>
            </div>
          </b-col>

          <!--    paid by employer-->
          <b-col md="4">
            <field-input
                :name="'paidByEmployer'"
                :rules="'required|decimal|min_value:0'"
                :model.sync="salary.paidByEmployer"
                :listIndex="index"
            />
          </b-col>

          <!--    Gross-->
          <b-col md="4">
            <field-input
                :name="'gross'"
                :rules="'required|decimal|min_value:0'"
                :model.sync="salary.gross"
                :listIndex="index"
            />
          </b-col>

          <!--    Net-->
          <b-col md="4">
            <field-input
                :name="'net'"
                :rules="'required|decimal|min_value:0'"
                :model.sync="salary.net"
                :listIndex="index"
            />
          </b-col>

          <!--    Paid vacation remaining-->
          <b-col md="4">
            <field-input
                :name="'paidVacationRemaining'"
                :rules="'decimal'"
                :model.sync="salary.paidVacationRemaining"
                :listIndex="index"
            />
          </b-col>

          <!--    Luncheon voucher quantity-->
          <b-col md="4">
            <field-input
                :name="'luncheonVoucherQuantity'"
                :rules="'integer'"
                :model.sync="salary.luncheonVoucherQuantity"
                :listIndex="index"
            />
          </b-col>

          <!--    luncheon voucher paid by employee -->
          <b-col md="4">
            <field-input
                :name="'luncheonVoucherPaidByEmployee'"
                :rules="'decimal'"
                :model.sync="salary.luncheonVoucherPaidByEmployee"
                :listIndex="index"
            />
          </b-col>

          <!--    Repayment -->
          <b-col md="6">
            <field-input
                :name="'repayment'"
                :rules="'decimal'"
                :model.sync="salary.repayment"
                :listIndex="index"
            />
          </b-col>

          <!--    Mileage allowance -->
          <b-col md="6">
            <field-input
                :name="'mileageAllowance'"
                :rules="'decimal'"
                :model.sync="salary.mileageAllowance"
                :listIndex="index"
            />
          </b-col>

        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import FieldInput from '@/components/input/Input'
import FieldFile from '@/components/input/File'
import FieldSelect from '@/components/input/Select'
import FieldDate from '@/components/input/Date'
import { capitalize, currency, percent } from '@/utils/filter'
import { isObject } from '@/utils/utils'
import store from '@/store'
import useAPI from '@/utils/useAPI'

export default {
  components: {
    FieldInput,
    FieldFile,
    FieldSelect,
    FieldDate
  },
  props: {
    salary: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    allowRemove: {
      type: Boolean
    },
    isEdition: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const currentBreakPoint = computed(() => {
      return store.getters['app/currentBreakPoint']
    })

    const employees = computed(() => {
      return store.getters['user/getEmployees']
    })
    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,
      percent,

      // Data

      // Computed
      currentBreakPoint,
      employees,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>