<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      :hide-footer="hideFooter"
      :ok-title="capitalize($t('validate'))"
      :cancel-title="capitalize($t('cancel'))"
      @hide="tryCloseSalariesModal"
      @hidden="handleCancel"
      @ok="handleOk"
  >
    <validation-observer
        ref="observerRef"
        #default="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(submitValidatedSalariesLocal)">

        <form-salaries
            :salaries="salaries"
            :allowEmpty="false"
            :isEdition="isEdition"
        />

        <button ref="submitSalariesFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '../../utils/filter'

import useAPI from '../../utils/useAPI'
import i18n from '@/libs/i18n'
import FormSalaries from '@/components/form/salaries/Salaries'

export default {
  components: {
    ValidationObserver,
    FormSalaries
  },
  props: {
    salaries: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const salariesLocal = ref(props.salaries)
    const submitSalariesFormRef = ref(null)
    const observerRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    const isEdition = computed(() => {
      return props.title == i18n.t('EditSalary')
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(salariesLocal, (val) => {
      emit('update:salaries', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseSalariesModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitSalariesFormRef.value.click()
      }
    }

    const submitValidatedSalariesLocal = () => {
      emit('submitValidated')
    }

    const handleOk = async (bvModalEvent) => {
      bvModalEvent.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedSalariesLocal()
      }
    }

    const handleCancel = () => {}

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      salariesLocal,
      submitSalariesFormRef,
      observerRef,

      // Computed
      hideFooter,
      isEdition,

      // Methods
      tryCloseSalariesModal,
      submitValidatedSalariesLocal,
      handleOk,
      handleCancel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    salaries: function (val) {
      this.salariesLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>