<template>
  <div>
    <b-overlay :show="isLoading">

      <h1>{{ $t('CustomerReceivable') }}</h1>
      <table class="receivableTable">
        <tr>
          <td colspan="2"
              class="px-1 pb-50">
            <label>
              {{ capitalize($t('unpaidBills:')) }} <b>{{
                currency(isLoading ? 0 : customerReceivableIndicator.total.amount)
              }}</b>
            </label>

            <b-progress :max="isLoading?0:customerReceivableIndicator.total.quantity"
                        height="1rem">
              <b-progress-bar
                  variant="primary"
                  :value="isLoading?0:customerReceivableIndicator.open.quantity"
                  show-value
              />
              <b-progress-bar
                  variant="warning"
                  :value="isLoading?0:customerReceivableIndicator.late.quantity"
                  show-value
              />
            </b-progress>
          </td>
        </tr>
        <tr>
          <td class="w-50 px-1">
            <div class="text-primary">
              <label class="text-primary">{{ $t('open:').toUpperCase() }}</label>
              <div>
                {{ currency(isLoading ? 0 : customerReceivableIndicator.open.amount) }}
              </div>
            </div>
          </td>
          <td class="px-1">
            <div class="text-warning">
              <label class="text-warning">{{ $t('late:').toUpperCase() }}</label>
              <div>
                {{ currency(isLoading ? 0 : customerReceivableIndicator.late.amount) }}
              </div>
            </div>
          </td>
        </tr>
      </table>

    </b-overlay>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../../utils/filter'
import { isEmptyObject } from '../../../../utils/utils'
import useAPI from '../../../../utils/useAPI'

export default {
  components: {},
  props: {
    customerReceivableIndicator: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isLoading = computed(() => {
      if (isEmptyObject(props.customerReceivableIndicator)) {
        return true
      } else {
        return false
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data

      // Computed
      isLoading,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>