<template>

  <generic-card
      :byContent="true"
      :allowDelete="allowDelete"

      @click="$emit('click')"
      @delete="$emit('deleteSalary')"

  >
    <template #content>
      <content-salary2
          :salary="salary"
          :isParentMethodPriority="true"
          :isPayable="isPayable"
          :isDownloadable="isDownloadable"
          :isViewable="isViewable"
          :isDeletable="false"
          :is-selected="isSelected"
          @view="$emit('view')"
          @pay="$emit('pay')"
      />
    </template>

  </generic-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import GenericCard from './Card'
import ContentSalary2 from './components/Salary2'

export default {
  components: {
    GenericCard,
    ContentSalary2
  },
  props: {
    salary: {
      type: Object,
      default: () => {}
    },
    isDownloadable: {
      type: Boolean,
      default: true
    },
    isViewable: {
      type: Boolean,
      default: false,
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    allowDelete: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>