<template>
  <div>

    <div
        ref="formSalaries"
        :key="componentKey"
    >

      <transition-group name="list-complete"
                        tag="p">

        <b-row
            v-for="(salary, index) in salaries"
            :key="index"
            ref="row"
            class="list-complete-item pb-2"
        >
          <salary
              :salary="salary"
              :index="index"
              :isEdition="isEdition"
              :allowRemove="allowRemove"
              @removeSalary="removeSalary(salary._uuid)"
          />

        </b-row>

      </transition-group>

    </div>
    <b-button
        v-if="!isEdition"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="primary"
        :class="{'mb-2': salaries.length}"
        @click="addNewSalary"
    >
      {{ $t('AddSalary') }}
    </b-button>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { heightTransition } from '@/utils/form'
import Ripple from 'vue-ripple-directive'
import Salary from './components/_salary'
import store from '@/store'

export default {
  directives: {
    Ripple,
  },
  components: {
    Salary
  },
  props: {
    salaries: {
      type: Array,
      default: () => []
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    isEdition: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const currentBreakPoint = computed(() => {
      return store.getters['app/currentBreakPoint']
    })

    const allowRemove = computed(() => {
      if (props.allowEmpty) {
        return true
      } else {
        if (props.salaries.length > 1) {
          return true
        } else {
          return false
        }
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      componentKey,

      // Computed
      currentBreakPoint,
      allowRemove,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    addNewSalary () {
      this.salaries.push({
        month: (new Date()).toISOString().slice(0, 10),
      })
    },
    removeSalary (uuid) {
      let position =this.salaries.findIndex(s => s._uuid == uuid)
      this.salaries.splice(position, 1)
      // this.salaries = this.salaries.filter(salary => salary._uuid != uuid)
      this.componentKey++
    },
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.allowEmpty && this.salaries.length == 0) {
        this.salaries.push({
          month: (new Date()).toISOString().slice(0, 10),
        })
      }
    })
  },
  created () {
  },
  destroyed () {
  },
}
</script>

<style lang="scss"
       scoped>
.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>