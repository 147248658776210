import store from '@/store'

export const useCharges = () => {

  const submitValidatedCharge = (charge) => {
    return new Promise((resolve, reject) => {
      if ('id' in charge && charge.id != null) {
        updateCharge(charge)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewCharge(charge)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewCharge = (charge) => {
    return new Promise((resolve, reject) => {
      store.dispatch('socialSecurityContribution2/addSocialSecurityContribution', charge)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateCharge = (charge) => {
    return new Promise((resolve, reject) => {
      store.dispatch('socialSecurityContribution2/updateSocialSecurityContribution', charge)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeCharge = (charge) => {
    return new Promise((resolve, reject) => {
      store.dispatch('socialSecurityContribution2/removeSocialSecurityContribution', charge)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const recognizeCharges = (chargesDocument) => {
    return new Promise((resolve, reject) => {
      store.dispatch('document/recognizeDocument', [chargesDocument, 'socialSecurityContribution'])
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadCharge = (chargesDocument) => {
    let link = document.createElement('a')
    link.href = chargesDocument.document.url
    link.target = '_blank'
    link.download = chargesDocument.document.name + chargesDocument.document.extension
    link.click()
  }

  // const getChargeIconStatus = (charge) => {
  //   if (getChargeStatus(charge) == 'draft') return { variant: 'secondary', icon: 'pencil-ruler' }
  //   else if (getChargeStatus(charge) == 'invoiced') return { variant: 'success', icon: 'file-invoice-dollar' }
  //   else return { variant: 'primary', icon: 'paper-plane' }
  // }

  return {
    submitValidatedCharge,
    removeCharge,
    recognizeCharges,
    downloadCharge,
    // getChargeIconStatus
  }
}