<template>
  <vue-apex-charts
      type="donut"
      :options="chartOptions"
      :series="values"
  />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, number } from '@/utils/filter'
import { useCharts } from './useCharts'

import VueApexCharts from 'vue-apexcharts'
import i18n from '@/libs/i18n'

export default {
  components: {
    VueApexCharts
  },
  props: {
    labels: {
      type: Array,
      default: () => []
    },
    values: {
      type: Array,
      default: () => []
    },
    total: {
      // type: String,
      default: '-'
    },
    isCurrencies: {
      type: Boolean,
      default: false
    },
    isHours: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const chartOptions = computed(() => {
      return {
        colors: getColors(),
        chart: {
          toolbar: {
            show: false,
          },
          type: 'donut'
        },
        labels: props.labels,
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  formatter (val) {
                    if (props.isCurrencies) {
                      return currency(val, true)
                    } else if (props.isHours) {
                      return Math.round(val) + ' ' + i18n.tc('hour', val)
                    } else {
                      return number(val)
                    }
                  },
                },
                total: {
                  show: true,
                  label: 'Total',
                  formatter () {
                    if (props.isCurrencies) {
                      return currency(totalLocal.value, true)
                    } else if (props.isHours) {
                      return Math.round(totalLocal.value) + ' ' + i18n.tc('hour', totalLocal.value)
                    } else {
                      return number(totalLocal.value)
                    }
                  }
                }
              },
            },
          }
        },
        tooltip: {
          y: {
            formatter (val) {
              if (props.isCurrencies) {
                return currency(val, true)
              } else if (props.isHours) {
                return Math.round(val) + ' ' + i18n.tc('hour', val)
              } else {
                return number(val)
              }
            },
          }
        }
      }
    })

    const totalLocal = computed(() => {
      if (props.total != '-') {
        return props.total
      } else {
        return props.values.reduce((sum, x) => sum += x)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { getColors } = useCharts()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components

      // Data

      // Computed
      chartOptions,
      totalLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>