<template>
  <b-row
      @click.stop="displayAmountsFields=false">
    <b-col cols="12">

      <!--        Header-->
      <b-card no-body>
        <b-card-body>
          <b-row>
            <!--      Left-->
            <b-col md="8">
              <b-row>
                <!-- Incoming invoice type -->
                <b-col
                    cols="12"
                    class="mb-1"
                >
                  <label>{{ capitalize($t('invoiceType')) }} :</label>

                  <div class="d-flex">
                    <div
                        @click="changeType('default')"
                        class="cursor-pointer mr-2 mr-md-3"
                        :class="{'text-primary': invoice.type == 'default'}"
                        v-b-tooltip.hover.bottom="capitalize($tc('generalExpenses'))"
                    >
                      <icon icon="wallet"
                            size="2x"/>
                    </div>
                    <div
                        @click="changeType('toll')"
                        class="cursor-pointer mr-2 mr-md-3"
                        :class="{'text-primary': invoice.type == 'toll'}"
                        v-b-tooltip.hover.bottom="capitalize($t('transport'))"
                    >
                      <icon icon="car-side"
                            size="2x"/>
                    </div>
                    <div
                        @click="changeType('catering')"
                        class="cursor-pointer mr-2 mr-md-3"
                        :class="{'text-primary': invoice.type == 'catering'}"
                        v-b-tooltip.hover.bottom="capitalize($t('catering'))"
                    >
                      <icon icon="utensils"
                            size="2x"/>
                    </div>
                    <div
                        @click="changeType('accommodation')"
                        class="cursor-pointer mr-2 mr-md-3"
                        :class="{'text-primary': invoice.type == 'accommodation'}"
                        v-b-tooltip.hover.bottom="capitalize($t('accommodation'))"
                    >
                      <icon icon="hotel"
                            size="2x"/>
                    </div>
                    <div
                        @click="changeType('production')"
                        class="cursor-pointer"
                        :class="{'text-primary': invoice.type == 'production'}"
                        v-b-tooltip.hover.bottom="capitalize($t('production'))"
                    >
                      <icon icon="tools"
                            size="2x"/>
                    </div>

                  </div>
                </b-col>

                <!-- Supplier -->
                <b-col cols="12">
                  <field-contact
                      v-show="['default', 'production'].includes(invoice.type)"
                      :name="$t('supplier')"
                      :contact.sync="invoice.supplierCompany"
                      :is-initial-contact-company="true"
                      :is-company-only="true"
                      :is-required="['default', 'production'].includes(invoice.type)"
                  />
                </b-col>

              </b-row>
            </b-col>

            <!--      Right-->
            <b-col
                md="4"
                class="pt-1"
            >
              <!--            <b-col-->
              <!--                md="4"-->
              <!--            >-->

              <div class="w-100 mx-auto"
                   style="max-width: 12rem">

                <!--            Pre tax amount-->
                <div
                    class="d-flex align-content-center justify-content-between"
                >
                  <p
                      style="width: 50%;"
                  >
                    {{ $t('PreTaxAmount:') }}
                  </p>
                  <p style="width: 50%"
                     class="text-right">
                    <field
                        :displayLabel="false"
                        :name="'preTaxAmount'"
                        :rules="'required'"
                        :model.sync="invoice.preTaxAmount"
                        @input="changePreTaxAmount('preTaxAmount', $event)"
                        :key="componentKey"
                        size="sm"
                        class="mb-0"
                        ref="inputFieldPreTaxAmount"
                    />
                  </p>
                </div>

                <!--            <VAT-->
                <div class="d-flex align-content-center justify-content-between">
                  <p
                      style="width: 50%"
                  >
                    {{ $t('VAT:') }}
                  </p>
                  <p style="width: 50%"
                     class="text-right">
                    <field
                        :name="'VAT'"
                        :displayLabel="false"
                        :rules="'required'"
                        :model.sync="invoice.vat"
                        @input="changePreTaxAmount('vat', $event)"
                        :key="componentKey"
                        ref="inputFieldVat"
                        size="sm"
                        class="mb-0"
                    />
                  </p>
                </div>
                <hr class="my-50">

                <!--            Total amount-->
                <div class="d-flex align-content-center justify-content-between">
                  <p
                      style="width: 50%"
                  >
                    {{ $t('TotalAmount:') }}
                  </p>
                  <p style="width: 50%"
                     class="text-right">
                    <field
                        :name="'totalAmount'"
                        :displayLabel="false"
                        :rules="'required'"
                        :model.sync="invoice.totalAmount"
                        @input="changePreTaxAmount('totalAmount', $event)"
                        :key="componentKey"
                        ref="inputFieldTotalAmount"
                        size="sm"
                        class="mb-0"
                    />
                  </p>
                </div>

              </div>
            </b-col>

            <!-- Title -->
            <b-col
                v-if="!['default', 'production'].includes(invoice.type)"
                cols="12"
            >
              <field-input
                  :model.sync="invoice.title"
                  :name="$t('title')"
                  :rules="'required'"
              />
            </b-col>

          </b-row>
        </b-card-body>
      </b-card>


      <!-- Tabs -->
      <b-card no-body>
        <b-card-body class="pt-0">
          <b-tabs>
            <!-- Terms a conditions -->
            <b-tab
                active
                :title="capitalize($t('conditions'))"
                :title-link-class="{'text-danger font-weight-bolder': isTermsTabError}"
            >
              <b-card-text>
                <tab-terms
                    :incoming-invoice="invoice"
                    @changePreTaxAmount="changePreTaxAmount"
                />
              </b-card-text>

            </b-tab>


            <!-- Included contacts  -->
            <b-tab
                v-if="!['default', 'production'].includes(invoice.type)"
                :title="capitalize($t('group'))"
                :title-link-class="{'text-danger font-weight-bolder': isGroupTabError}"
            >

              <b-card-text>
                <tab-companions
                    :incoming-invoice="invoice"
                />
              </b-card-text>
            </b-tab>

            <!-- Products -->
            <b-tab
                v-if="['default', 'production'].includes(invoice.type)"
                :title="capitalize($tc('product', 2)) + ' (' + invoice.products.length + ')'"
                :title-link-class="{'text-danger font-weight-bolder': isProductsTabError}"
            >
              <!--              <b-card-text>-->
              <tab-products
                  :incoming-invoice="invoice"
                  @isProductMounted="setIsProductMounted"
                  @changePreTaxAmount="changePreTaxAmount"
              />
              <!--              </b-card-text>-->
            </b-tab>

            <!-- Workflow & phase -->
            <b-tab :title="capitalize($t('workflow'))">
              <b-card-text>
                <tab-workflow :incoming-invoice="invoice"/>
              </b-card-text>
            </b-tab>

            <!--Receipt-->
            <b-tab
                :title="capitalize($tc('invoice'))"
                :title-link-class="{'text-danger font-weight-bolder': isInvoiceTabError}"
            >
              <b-card-text>
                <tab-invoice :incoming-invoice="invoice"/>
              </b-card-text>
            </b-tab>

            <!--Payments-->
            <!--            <b-tab-->
            <!--                :title="capitalize($tc('payment', 2)) + ' (' +(invoice.bankOperations ? invoice.bankOperations.length : 0)+ ')'"-->
            <!--                :title-link-class="{'text-danger font-weight-bolder': isPaymentTabError}"-->
            <!--            >-->
            <!--              <b-card-text>-->
            <!--                <tab-payments :incoming-invoice="invoice"/>-->
            <!--              </b-card-text>-->
            <!--            </b-tab>-->


          </b-tabs>
        </b-card-body>

      </b-card>

    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, percent } from '@/utils/filter'
import { getRoute } from '@/utils/utils'
import { isObject } from '../../../utils/utils'

import Field from '@/components/input/Input'
import FieldDate from '@/components/input/Date'
import FieldInput from '@/components/input/Input'
import FieldSelect from '@/components/input/Select'
import FieldContact from '../../input/Contact2.vue'
import FieldDiscount from '@/components/input/Discount'
import FieldFile from '@/components/input/File'
import FieldEmployee from '@/components/input/Employee'
import FormProducts from '@/components/form/products/Products'
import FormIncludedContacts from '@/components/form/includedContact/IncludedContacts'
import store from '@/store'
import useAPI from '@/utils/useAPI'

import TabProducts from './components/_tabProducts'
import TabTerms from './components/_tabTerms&conditions'
import TabWorkflow from './components/_tabWorkflow'
import TabInvoice from './components/_tabInvoice'
import TabCompanions from './components/_tabCompanions'
import TabPayments from './components/_tabPayments'

export default {
  components: {
    Field,
    FieldDate,
    FieldInput,
    FieldSelect,
    FieldContact,
    FieldDiscount,
    FieldFile,
    FieldEmployee,
    FormProducts,
    FormIncludedContacts,
    TabProducts,
    TabTerms,
    TabWorkflow,
    TabInvoice,
    TabCompanions,
    TabPayments
  },
  props: {
    invoice: {
      type: Object,
      default: () => {}
    },
    formErrors: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // console.log(JSON.parse(JSON.stringify(props.invoice)))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)
    const inputFieldPreTaxAmount = ref(null)
    const inputFieldVat = ref(null)
    const inputFieldTotalAmount = ref(null)
    const displayWorkflow = ref(false)

    const displayAmountsFields = ref(false)
    const underlineAmountsFields = ref(false)
    const isProductsTabError = ref(false)
    const isTermsTabError = ref(false)
    const isInvoiceTabError = ref(false)
    const isGroupTabError = ref(false)
    const isPaymentTabError = ref(false)

    const isProductMounted = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { myCompany, paymentMethods, paymentTerms, workflows } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(isProductMounted, val => {
    // console.log(val)
    // })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const changePreTaxAmount = (input = null, value = null) => {
      // if (isProductMounted.value) {

      if (['preTaxAmount', 'vat', 'totalAmount'].includes(input)) {

        if (input == 'preTaxAmount') {
          let preTaxAmount = (isNaN(value) || value == null || value == '') ? 0 : parseFloat(value)
          let vat = preTaxAmount * .2

          props.invoice.preTaxAmount = preTaxAmount
          props.invoice.totalAmount = (preTaxAmount + vat).toFixed(2)

          inputFieldPreTaxAmount.value.updateLocal(preTaxAmount)
          inputFieldVat.value.updateLocal(vat.toFixed(2))
          inputFieldTotalAmount.value.updateLocal((preTaxAmount + vat).toFixed(2))

        } else if (input == 'vat') {
          let preTaxAmount = parseFloat(props.invoice.preTaxAmount)
          let vat = parseFloat(value)

          props.invoice.vat = vat
          props.invoice.totalAmount = preTaxAmount + vat

          inputFieldVat.value.updateLocal(vat)
          inputFieldTotalAmount.value.updateLocal(preTaxAmount + vat)
        }

      } else {
        let subPreTaxAmount = 0
        props.invoice.preTaxAmount = 0
        props.invoice.vat = 0
        props.invoice.totalAmount = 0

        props.invoice.products.forEach(product => {
          // console.log(product)
          subPreTaxAmount += parseFloat(product.preTaxAmount)
          props.invoice.vat += parseFloat(product.vat)
        })

        let discount = parseFloat(props.invoice.discount ? props.invoice.discount : 0)
        let isDiscountPercent = props.invoice.isDiscountPercent
        if (input == 'discount') {
          if (typeof value === 'boolean') {
            isDiscountPercent = value
          } else {
            discount = parseFloat(value ? value : 0)
          }
        }

        if (discount != 0) {
          if (isDiscountPercent) {
            props.invoice.preTaxAmount = parseFloat(
                subPreTaxAmount * (1 - discount / 100)
            ).toFixed(2)

            props.invoice.vat = parseFloat(
                props.invoice.vat * (1 - discount / 100)
            ).toFixed(2)

          } else {
            props.invoice.preTaxAmount = subPreTaxAmount - discount
            props.invoice.vat = 0

            let discountPercent = discount / subPreTaxAmount

            props.invoice.products.forEach(product => {
              let discountedPreTaxAmount = parseFloat(product.preTaxAmount) * (1 - discountPercent)
              let rate = store.getters['vat/getVat'](product.vatRate).rate

              props.invoice.vat += discountedPreTaxAmount * rate
            })

          }
        } else {
          props.invoice.preTaxAmount = subPreTaxAmount
        }

        if (props.invoice.preTaxAmount < 0) {
          props.invoice.preTaxAmount = 0
          props.invoice.vat = 0
        }

        // let duty = parseFloat(props.invoice.duty ? props.invoice.duty : 0)
        // props.invoice.preTaxAmount += duty
        // props.invoice.vat += parseFloat(duty * 0.2).toFixed(2)

        let tax = parseFloat(props.invoice.tax ? props.invoice.tax : 0)

        props.invoice.totalAmount =
            (parseFloat(props.invoice.preTaxAmount) +
                parseFloat(props.invoice.vat) +
                tax).toFixed(2)

        componentKey.value++
      }
      // }
    }

    const changeType = (newType) => {
      props.invoice.type = newType
      componentKey.value++

      if (newType == 'default' || newType == 'production') {
        props.invoice.title = null
        props.invoice.includedContacts = []
      } else {
        props.invoice.products = []
      }

    }

    const setIsProductMounted = () => {
      setTimeout(() => {
        isProductMounted.value = true
      }, 200)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().name == 'Incoming invoices') displayWorkflow.value = true

    return {
      // Components
      capitalize,
      currency,
      percent,
      isObject,

      // Data
      componentKey,
      inputFieldPreTaxAmount,
      inputFieldVat,
      inputFieldTotalAmount,
      displayAmountsFields,
      underlineAmountsFields,

      // Computed
      myCompany,
      paymentMethods,
      paymentTerms,
      workflows,
      isProductsTabError,
      isTermsTabError,
      isInvoiceTabError,
      isGroupTabError,
      isPaymentTabError,
      // selectedContact,

      // Methods
      changePreTaxAmount,
      changeType,
      displayWorkflow,
      setIsProductMounted,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    formErrors: function (val) {
      this.isProductsTabError = false
      this.isTermsTabError = false
      this.isInvoiceTabError = false
      this.isGroupTabError = false

      for (const [field, errorArrray] of Object.entries(val)) {
        // console.log(field, errorArrray);

        if (
            errorArrray.length > 0 &&
            (
                field.startsWith('prix unitaire') || field.startsWith('unit price') ||
                field.startsWith('quantité') || field.startsWith('quantity')
            )
        ) {
          this.isProductsTabError = true
        }

        if (
            (
                (field == 'numéro de facture' || field == 'bill number') ||
                (field == 'date de facture' || field == 'billing date') ||
                (field == 'moyen de paiement' || field == 'payment method') ||
                (field == 'date de paiement' || field == 'payment date')
            ) &&
            errorArrray.length > 0
        ) {
          this.isTermsTabError = true
        }

        if (
            (field == 'upload' || field == 'upload') &&
            errorArrray.length > 0) {
          this.isInvoiceTabError = true
        }

        if (
            errorArrray.length > 0 &&
            (
                field.startsWith('nombre') || field.startsWith('number') ||
                field.startsWith('contact') || field.startsWith('contact')
            )
        ) {
          this.isGroupTabError = true
        }

      }
    }
  },
  methods: {},
  mounted () {
    // console.log(this.invoice)
  },
  created () {
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/invoice.scss";
@import '~@/assets/scss/vue/libs/vue-select.scss';
</style>