<template>
  <div class="mt-3">
    <b-overlay :show="isLoading">

      <h1>{{ capitalize($t('expenseItems')) }}</h1>

      <div class="accountingCashFlow">
        <!--Graphique-->
        <chart-donut
            :labels="labels"
            :values="values"
            :is-currencies="true"
        />
      </div>

    </b-overlay>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../../utils/filter'
import { isEmptyObject } from '../../../../utils/utils'

import ChartDonut from '../../../../components/chart/Donut.vue'
import i18n from '../../../../libs/i18n'

export default {
  components: {
    ChartDonut
  },
  props: {
    expenseItems: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isLoading = computed(() => {
      if (isEmptyObject(props.expenseItems)) {
        return true
      } else {
        return false
      }
    })

    const labels = computed(() => {
      if (isEmptyObject(props.expenseItems)) {
        return []
      }
      let labels = []
      Object.keys(props.expenseItems).forEach(l => labels.push(capitalize(i18n.t(l))))

      return labels
    })

    const values = computed(() => {
      if (isEmptyObject(props.expenseItems)) {
        return []
      }

      return Object.values(props.expenseItems)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data

      // Computed
      isLoading,
      labels,
      values,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>