<template>
  <b-row>
    <!-- Bill number-->
    <b-col md="6">
      <field-input
          :name="'billNumber'"
          :isGroup="true"
          :rules="(['toll', 'accommodation', 'catering'].includes(incomingInvoice.type) )?'':'required'"
          :model.sync="incomingInvoice.billNumber"
          class="input-group-merge w-100"
      >
        <template v-slot:prepend>
          <icon icon="hashtag"/>
        </template>
      </field-input>
    </b-col>

    <!-- Billing date-->
    <b-col md="6">
      <field-date
          :name="'billingDate'"
          :model.sync="incomingInvoice.billingDate"
          :isRequired="true"
          class="w-100"
      />
    </b-col>

    <!-- Payment method -->
    <b-col :md="(isEmployeePaymentMethod || isBankAccountRequired)?4:6">
      <field-select
          :model.sync="incomingInvoice.paymentMethod"
          :name="'paymentMethod'"
          :options="paymentMethods"
          :label="'name'"
          :isRequired="true"
          :clearable="false"
      />
    </b-col>

    <!-- Bank account -->
    <b-col
        v-if="isBankAccountRequired"
        md="4"
    >
      <field-select
          :name="capitalize($t('accountInvolved'))"
          :model.sync="incomingInvoice.bankAccount"
          :options="autocompleteBankAccounts"
          :is-required="true"
      />
    </b-col>

    <!-- Employee -->
    <b-col
        v-if="isEmployeePaymentMethod"
        md="4"
    >
      <field-employee
          :model.sync="incomingInvoice.paidBy"
          :isRequired="true"
      />
    </b-col>

    <!-- Payment date-->
    <b-col :md="(isEmployeePaymentMethod || isBankAccountRequired)?4:6">
      <field-date
          :name="'paymentDate'"
          :model.sync="incomingInvoice.paymentDate"
          :isRequired="true"
          class="w-100"
      />
    </b-col>

    <!-- Col: Discount -->
    <b-col cols="12">
      <field-discount
          :name="'discount'"
          :discount.sync="incomingInvoice.discount"
          :isDiscountPercent.sync="incomingInvoice.isDiscountPercent"
          @input="$emit('changePreTaxAmount', 'discount', $event)"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject } from '../../../../utils/utils'
import { capitalize } from '../../../../utils/filter'

import useAPI from '../../../../utils/useAPI'
import FieldInput from '@/components/input/Input'
import FieldSelect from '../../../input/Select2.vue'
import FieldDate from '@/components/input/Date'
import FieldDiscount from '@/components/input/Discount'
import FieldEmployee from '@/components/input/Employee'

export default {
  components: {
    FieldInput,
    FieldSelect,
    FieldDate,
    FieldDiscount,
    FieldEmployee
  },
  props: {
    incomingInvoice: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      autocompleteBankAccounts,
      paymentMethods,
      paymentTerms
    } = useAPI()

    const isEmployeePaymentMethod = computed(() => {
      return isObject(props.incomingInvoice.paymentMethod) ? props.incomingInvoice.paymentMethod.id == 5 : props.incomingInvoice.paymentMethod == 5
    })

    const isBankAccountRequired = computed(() => {
      return !isEmployeePaymentMethod.value && (isObject(props.incomingInvoice.paymentMethod) ? props.incomingInvoice.paymentMethod.id != 7 : props.incomingInvoice.paymentMethod != 7)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      isObject,
      capitalize,

      // Data

      // Computed
      paymentMethods,
      paymentTerms,
      isEmployeePaymentMethod,
      isBankAccountRequired,
      autocompleteBankAccounts,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>