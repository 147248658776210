<template>
  <div>

    <div
        ref="formIncludedContacts"
        class="repeater-form"
        :style="{height: trHeight}"
        :key="componentKey"
    >
      <b-row
          v-for="(includedContact, index) in includedContacts"
          :key="index"
          ref="row"
          class="pb-2"
      >
        <included-contact
            :includedContact="includedContact"
            :index="index"
            :allowRemove="allowRemove(index)"
            @removeIncludedContact="removeIncludedContact(index)"
        />

      </b-row>
    </div>

    <button-add
        size="sm"
        @click="addNewIncludedContact"
        :text="$t('AddContact')"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { heightTransition } from '@/utils/form'
import {getUserData} from '../../../utils/utils'

import Ripple from 'vue-ripple-directive'
import IncludedContact from './components/_includedContact'
import store from '@/store'
import ButtonAdd from '@/components/button/Add'

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    IncludedContact,
    ButtonAdd
  },
  props: {
    includedContacts: {
      type: Array,
      default: () => []
    },
    allowEmpty: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const currentBreakPoint = computed(() => {
      return store.getters['app/currentBreakPoint']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      getUserData,

      // Data
      componentKey,

      // Computed
      currentBreakPoint,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    addNewIncludedContact () {
      this.includedContacts.push({})

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeIncludedContact (index) {
      if (this.allowRemove(index)) {
        this.includedContacts.splice(index, 1)
        this.componentKey++

        this.$nextTick(() => {
          if (typeof this.$refs.row[0] !== 'undefined') {
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
          } else {
            this.initTrHeight()
          }
        })
      }
    },
    allowRemove (index) {
      return this.allowEmpty || (!this.allowEmpty && this.includedContacts.length>1)
    },
    initTrHeight () {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formIncludedContacts.scrollHeight)
      })
    },
  },
  mounted () {
    this.initTrHeight()

    this.$nextTick( () => {
      if (!this.allowEmpty && this.includedContacts.length == 0) {
        this.includedContacts.push({
          company: store.getters['company/getMyCompany'],
          number: 1
        })

        this.$nextTick(() => {
          if (['md', 'lg', 'xl'].includes(this.currentBreakPoint)) {
            this.trSetHeight(this.includedContacts.length * 94.36)
          } else {
            this.trSetHeight(this.includedContacts.length * 800.906)
          }
        })
      }

      if (this.includedContacts.length) {
        this.$nextTick(() => {
          if (['md', 'lg', 'xl'].includes(this.currentBreakPoint)) {
            this.trSetHeight(this.includedContacts.length * 94.36)
          } else {
            this.trSetHeight(this.includedContacts.length * 800.906)
          }
        })
      }

    })
  },
  created () {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>

<style lang="scss"
       scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>