export const useCharts = () => {
  const getColors = () => {
    return [
      '#008ffb',
      '#00e396',
      '#feb019',
      '#ff4560',
      '#775dd0',
      '#07625c',
      '#994F29']
  }

  return {
    getColors
  }
}