<template>
  <div>

    <base-template
        title="SocialSecurityContributions"
        itemView="list"
        :text-button-add="$t('Add')"
        :columns="columns"
        :filters="filters"
        :items="socialSecurityContributions2"
        :context="socialSecurityContributionsContext"
        :isActionColumnEnabled="true"
        :open-view-window="openViewWindow"
        :allow-add="allowAdd"
        :allow-add-by-upload="allowAddByUpload"
        :display-custom-fields="displayCustomFields&&(allowAdd||allowAddByUpload)"

        @fetch="$emit('reloadCharges', $event)"
        @selectItem="showDetails"
        @addItem="chargesModalShow=true"
        @addItemByUpload="chargesByUploadModalShow = true"
    >
      <template #bottomTitle>
        <i v-if="socialSecurityContributionsContext.custom_fields && displayCustomFields && !allowAdd && !allowAddByUpload">
          {{ currency(socialSecurityContributionsContext.custom_fields.totalAmounts) }}
        </i>
      </template>
      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <charge-card
            :charge="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-payable="allowPayment"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="showDetails(item)"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadCharge(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Edit -->
          <button-edit
              v-if="allowEdit"
              @click.native.stop="editCharge(item)"
              v-b-tooltip.hover.left="$t('Edit')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Delete-->
          <button-remove
              v-if="allowRemove"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              @click.native.stop="deleteChargeAlert(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>


      <!--  =========================== -->
      <!--  ===     Select view     === -->
      <!--  =========================== -->
      <template #leftViewWindow="{item}">
        <charge-card
            :charge="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==selectedItems[0].id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #windowView
                v-if="selectedItems.length">
        <div class="d-flex justify-content-between mb-1">
          <h4>{{ selectedItems[0]._display }}</h4>

          <div>
            <!--          Download-->
            <button-download
                @click.native.stop="downloadCharge(selectedItems[0])"
                v-b-tooltip.hover.left="$t('Download')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Edit -->
            <button-edit
                v-if="allowEdit"
                @click.native.stop="editCharge(selectedItems[0])"
                v-b-tooltip.hover.left="$t('Edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Close-->
            <button-close
                @click.native.stop="showDetails(null)"
                v-b-tooltip.hover.left="$t('Close')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />
          </div>
        </div>

        <charge-view
            class="invoice-preview"
            :salary="selectedItems[0]"
        />


      </template>
    </base-template>

    <!-- modal new social security contributions-->
    <modal-social-security-contributions
        :socialSecurityContributions.sync="currentCharges"
        :title="chargeModalTitle"
        :isOpen.sync="chargesModalShow"
        @submitValidated="submitValidatedChargeLocal(currentCharges)"
    />

    <!-- modal social security contributions by upload-->
    <modal-upload
        :singleFile.sync="newChargesFile"
        :documentType="'socialSecurityContribution'"
        :title="capitalize($tc('socialSecurityContribution',2))"
        :isOpen.sync="chargesByUploadModalShow"

    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'
import { useCharges } from '../../views/humanResources/charges/useCharges'

import BaseTemplate from '../base/Base'
import ChargeCard from '../card/Charge.vue'
import ButtonView from '../button/View'
import ButtonDownload from '../button/Download'
import ButtonEdit from '../button/Edit.vue'
import ButtonRemove from '../button/Remove.vue'
import ButtonClose from '../button/Close'
import ChargeView from './ChargeView.vue'
import ModalSocialSecurityContributions from '../prompt/SocialSecurityContributions'
import ModalUpload from '../prompt/Upload'

import i18n from '../../libs/i18n'
import useAPI from '../../utils/useAPI'
import store from '../../store'

export default {
  components: {
    BaseTemplate,
    ChargeCard,
    ButtonView,
    ButtonDownload,
    ButtonEdit,
    ButtonRemove,
    ButtonClose,
    ChargeView,
    ModalSocialSecurityContributions,
    ModalUpload,
  },
  props: {
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: true
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    allowRemove: {
      type: Boolean,
      default: true
    },
    allowPayment: {
      type: Boolean,
      default: true
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.tc('supplier')),
        key: 'supplier',
        sortable: true,
        cell: {
          useDisplay: true
        }
      },
      {
        display: capitalize(i18n.t('month')),
        key: 'month',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.t('amount')),
        key: 'preTaxAmount',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
    ])
    const filters = ref([])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const chargesModalShow = ref(false)
    const chargesByUploadModalShow = ref(false)
    const chargeModalTitle = ref(i18n.t('NewSocialSecurityContribution'))
    const currentCharges = ref([])
    const newChargesFile = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { socialSecurityContributions2, socialSecurityContributionsContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(chargesModalShow, (val) => {
      if (val == false) {
        resetCharge()
      }
    })

    watch(newChargesFile, (val) => {
      if (val != null) {
        recognizeCharges(val)
            .then(response => {
              chargesByUploadModalShow.value = false
              currentCharges.value = response.data
              newChargesFile.value = null
              chargesModalShow.value = true

              let i = 0
              currentCharges.value.forEach(s => {
                s._uuid = i
                i++
              })

            })

      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      downloadCharge,
      removeCharge,
      submitValidatedCharge,
      recognizeCharges
    } = useCharges()

    const showDetails = (charge) => {
      if (charge != null) {
        store.dispatch('socialSecurityContribution2/getSocialSecurityContribution', charge.id)
            .then(response => {
              selectedItems.value = [charge]
              selectedItems.value[0] = response
              openViewWindow.value = true
            })

      } else {
        selectedItems.value = []
        openViewWindow.value = false
      }
    }

    const editCharge = (charges) => {
      chargeModalTitle.value = i18n.t('EditSocialSecurityContribution')
      currentCharges.value = [JSON.parse(JSON.stringify(charges))]
      chargesModalShow.value = true
    }

    const submitValidatedChargeLocal = (charges) => {
      charges.forEach(charge => {
        submitValidatedCharge(charge).then(response => {
          chargesModalShow.value = false
          // console.log(response)
        })
      })
    }

    const resetCharge = () => {
      currentCharges.value = JSON.parse(JSON.stringify([]))
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      columns,
      filters,
      openViewWindow,
      selectedItems,
      chargesModalShow,
      chargesByUploadModalShow,
      currentCharges,
      chargeModalTitle,
      newChargesFile,

      // Computed
      socialSecurityContributions2,
      socialSecurityContributionsContext,

      // Methods
      downloadCharge,
      removeCharge,
      showDetails,
      editCharge,
      submitValidatedChargeLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteChargeAlert (employee) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theSocialSecurityContribution') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeCharge(employee)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>