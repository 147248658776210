<template>
  <div>

    <base-template
        title="Salaries"
        itemView="list"
        :text-button-add="$t('Add')"
        :columns="columns"
        :filters="filters"
        :items="salaries2"
        :context="salariesContext"
        :isActionColumnEnabled="true"
        :open-view-window="openViewWindow"
        :allow-add="allowAdd"
        :allow-add-by-upload="allowAddByUpload"
        :display-custom-fields="displayCustomFields&&(allowAdd||allowAddByUpload)"

        @fetch="$emit('reloadSalaries', $event)"
        @selectItem="showDetails"
        @addItem="salariesModalShow=true"
        @addItemByUpload="salariesByUploadModalShow = true"
    >
      <template #bottomTitle>
        <i v-if="salariesContext.custom_fields && displayCustomFields && !allowAdd && !allowAddByUpload">
          {{ currency(salariesContext.custom_fields.excludingExpensesAndReimbursements) }}
          {{ $t('excludingExpensesAndReimbursements') }}
        </i>
      </template>

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <salary-card
            :salary="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-payable="allowPayment"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="showDetails(item)"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadSalary(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Edit -->
          <button-edit
              v-if="allowEdit"
              @click.native.stop="editSalary(item)"
              v-b-tooltip.hover.left="$t('Edit')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Delete-->
          <button-remove
              v-if="allowRemove"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              @click.native.stop="deleteSalaryAlert(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>


      <!--  =========================== -->
      <!--  ===     Select view     === -->
      <!--  =========================== -->
      <template #leftViewWindow="{item}">
        <salary-card
            :salary="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==selectedItems[0].id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #windowView
                v-if="selectedItems.length">
        <div class="d-flex justify-content-between mb-1">
          <h4>{{ selectedItems[0]._display }}</h4>

          <div>
            <!--          Download-->
            <button-download
                @click.native.stop="downloadSalary(selectedItems[0])"
                v-b-tooltip.hover.left="$t('Download')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Edit -->
            <button-edit
                v-if="allowEdit"
                @click.native.stop="editSalary(selectedItems[0])"
                v-b-tooltip.hover.left="$t('Edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Close-->
            <button-close
                @click.native.stop="showDetails(null)"
                v-b-tooltip.hover.left="$t('Close')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />
          </div>
        </div>

        <salary-view
            class="invoice-preview"
            :salary="selectedItems[0]"
        />


      </template>
    </base-template>

    <!-- modal salary by upload-->
    <modal-upload-salary
        :singleFile.sync="newSalaryFile"
        :documentType="'salary'"
        :title="capitalize($t('salary'))"
        :isOpen.sync="salariesByUploadModalShow"

    />

    <!-- modal salaries-->
    <modal-salaries
        :salaries.sync="currentSalaries"
        :title="salaryModalTitle"
        :isOpen.sync="salariesModalShow"
        @submitValidated="submitValidatedSalariesLocal(currentSalaries)"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'

import BaseTemplate from '../base/Base'
import SalaryCard from '../card/Salary2.vue'
import ButtonView from '../button/View'
import ButtonDownload from '../button/Download'
import ButtonEdit from '../button/Edit.vue'
import ButtonRemove from '../button/Remove.vue'
import ButtonClose from '../button/Close'
import SalaryView from './SalaryView.vue'
import ModalUploadSalary from '../prompt/Upload'
import ModalSalaries from '../prompt/Salaries'

import i18n from '../../libs/i18n'
import useAPI from '../../utils/useAPI'
import { useSalaries } from '../../views/humanResources/salaries2/useSalaries'
import store from '../../store'

export default {
  components: {
    BaseTemplate,
    SalaryCard,
    ButtonView,
    ButtonDownload,
    ButtonEdit,
    ButtonRemove,
    ButtonClose,
    SalaryView,
    ModalUploadSalary,
    ModalSalaries
  },
  props: {
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: true
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    allowRemove: {
      type: Boolean,
      default: true
    },
    allowPayment: {
      type: Boolean,
      default: true
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('month')),
        key: 'month',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.tc('employee')),
        key: 'employee',
        sortable: true,
        cell: {
          useDisplay: true
        }
      },
      {
        display: capitalize(i18n.t('paidByEmployer')),
        key: 'paidByEmployer',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
      {
        display: capitalize(i18n.t('net')),
        key: 'net',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
      {
        display: capitalize(i18n.t('paidVacationRemaining')),
        key: 'paidVacationRemaining',
        sortable: true,
        cell: {}
      },
      {
        display: capitalize(i18n.t('luncheonVoucherQuantity')),
        key: 'luncheonVoucherQuantity',
        sortable: true,
        cell: {}
      },

    ])
    const filters = ref([])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const newSalaryFile = ref(null)
    const salariesByUploadModalShow = ref(false)
    const currentSalaries = ref([])
    const salaryModalTitle = ref(i18n.t('NewSalary'))
    const salariesModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { salaries2, salariesContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newSalaryFile, (val) => {
      if (val != null) {
        recognizeSalary(val)
            .then(response => {
              // console.log(response.data)
              salaryByUploadModalShow.value = false
              currentSalaries.value = response.data
              newSalaryFile.value = null
              salariesModalShow.value = true

              let i = 0
              currentSalaries.value.forEach(s => {
                s._uuid = i
                i++
              })

            })
      }

    })

    watch(salariesModalShow, val => {
      if (val == false) {
        currentSalaries.value = []
        salaryModalTitle.value = i18n.t('NewSalary')
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      recognizeSalary,
      downloadSalary,
      removeSalary,
      submitValidatedSalary
    } = useSalaries()

    const showDetails = (salary) => {
      if (salary != null) {
        store.dispatch('salary2/getSalary', salary.id)
            .then(response => {
              selectedItems.value = [salary]
              selectedItems.value[0] = response
              openViewWindow.value = true
            })

      } else {
        selectedItems.value = []
        openViewWindow.value = false
      }
    }

    const editSalary = (salary) => {
      salaryModalTitle.value = i18n.t('EditSalary')

      store.dispatch('salary2/getSalary', salary.id)
          .then(salary => {
            currentSalaries.value = [salary]
            salariesModalShow.value = true
          })
    }

    const submitValidatedSalariesLocal = (salaries) => {
      // console.log(JSON.parse(JSON.stringify(salaries)))
      salaries.forEach(salary => {
        submitValidatedSalary(salary)
            .then(response => {
              salariesModalShow.value = false
              resetSalary()
            })
      })
    }

    const resetSalary = () => {
      currentSalaries.value = JSON.parse(JSON.stringify([]))
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetSalary()

    return {
      // Components
      capitalize,
      currency,

      // Data
      columns,
      filters,
      openViewWindow,
      selectedItems,
      salariesModalShow,
      salariesByUploadModalShow,
      newSalaryFile,
      currentSalaries,
      salaryModalTitle,

      // Computed
      salaries2,
      salariesContext,

      // Methods
      downloadSalary,
      removeSalary,
      showDetails,
      editSalary,
      submitValidatedSalariesLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteSalaryAlert (salary) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theSalary') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeSalary(salary)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>